import request from '@/service/lib/request';


export function getConfig() {
  return request({
    url: '/settings/settings',
    method: 'get'
  });
}


export function saveConfig(data) {
  return request({
    url: '/settings/settings',
    method: 'post',
    data
  });
}