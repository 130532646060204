<template>
  <div class="main-conent main-conent-minheight form-footer" v-loading.async="loading">

    <page-header into="当前页面配置信息保存后立即生效"><strong>系统配置</strong></page-header>
    <el-form :rules="rules" ref="ruleForm" label-width="160px" size="medium">
      <el-tabs v-model="activeName">

        <el-tab-pane label="圈子设置" name="first">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span>圈子设置</span>
            </div>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="圈子标签" prop="tags">
                  <el-select v-model="tagOptions" multiple filterable allow-create default-first-option
                    placeholder="请编辑标签" style="min-width:100%;">
                    <el-option v-for="(item,index) in tagOptions" :key="index" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="圈子是否需要审核" prop="needAudit">
                  <el-switch v-model="configForm.needAudit" active-text="需要审核" inactive-text="无需审核" />
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="圈子审核通过奖励功力值" prop="needAudit">
                  <el-input-number v-model="configForm.auditPoint"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="圈子分类选项" prop="tags">
                  <el-select v-model="categoryOptions" multiple filterable allow-create default-first-option
                    placeholder="请编辑标签" style="min-width:100%;">
                    <el-option v-for="(item,index) in categoryOptions" :key="index" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="功力值规则" name="second">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span>功力值规则</span>
            </div>
            <el-row :gutter="24">
              <div style="display:flex;flex-flow: row nowrap;justify-content: space-around;">
                <div id="div1" style="width: 375px;">

                </div>
              </div>
            </el-row>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="分享海报设置" name="third">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span>分享海报设置</span>
            </div>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="分享海报模板" prop="shareImage">
                  <el-upload :action="uploadUrl" class="avatar-uploader" :show-file-list="false"
                    :on-success="handleUploadSuccess" :on-error="handleUploadError" :headers="uploadHeader">
                    <el-image v-if="configForm.shareImage" :src="FullImgUrl(configForm.shareImage)" fit="fill"
                      class="shareImageSize">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>

            </el-row>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="订阅意向设置" name="fourth">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span>订阅意向设置</span>
            </div>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="顾问电话" prop="salerMobile">
                  <el-input v-model="configForm.salerMobile"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="二维码" prop="supportQrCode">
                  <el-upload :action="uploadUrl" class="avatar-uploader" :show-file-list="false"
                    :on-success="handleUploadQrcodeSuccess" :on-error="handleUploadError" :headers="uploadHeader">
                    <el-image v-if="configForm.supportQrCode" class="qrCodeImageSize"
                      :src="FullImgUrl(configForm.supportQrCode)">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <form-footer-toolbar class="text-right">
      <el-button type="primary" @click="submitForm()">提交</el-button>
    </form-footer-toolbar>

  </div>

</template>
<script>
import store from '@/store';
import PageHeader from '@/layout/components/PageHeader';
import FormFooterToolbar from '@/layout/components/FormFooterToolbar';
import { getConfig, saveConfig } from '@/service/settings.js';
import E from "wangeditor";

export default {
  components: {
    PageHeader,
    FormFooterToolbar
  },

  data() {
    return {
      activeName: 'first',
      loading: false,
      editor: Object,
      uploadUrl: process.env.VUE_APP_APIHOST + 'file/uploadserverimg',
      uploadHeader: {
        "Authorization": "Bearer " + store.getters.token
      },
      configForm: {
        tags: '',
        salerMobile: '',
        needAudit: true,
        auditPoint: 0,
        pointContent: '',
        shareImage: '',
        supportQrCode: '',
        categories: ''
      },
      tagOptions: [],
      categoryOptions:[],
      rules: {

      }
    };
  },
  computed: {
    FullImgUrl() {
      return function (img) {
        let fullImg = process.env.VUE_APP_HOST + img;
        return fullImg;
      };
    }
  },
  created() {
    this.dataBinding();
  },
  mounted() {
    this.wangEditorInit().then(val => {
      this.dataBinding();
    });
  },
  methods: {
    //数据绑定
    dataBinding() {
      this.loading = true;
      getConfig().then(res => {
        this.configForm = res;
        this.tagOptions = res.tags.split(',');
        this.categoryOptions=res.categories.split(',');
        this.editor.txt.html(res.pointContent);
        this.loading = false;
      });
    },
    //提交
    submitForm(formName) {
      this.configForm.tags = this.tagOptions.join(',');
      this.configForm.categories = this.categoryOptions.join(',');
      this.loading = true;
      saveConfig(this.configForm).then(res => {
        if (res === true)
          this.$message.success('配置保存成功');
        else
          this.$message.error('配置保存失败，请刷新页面后重试');
        this.loading = false;
      });
    },
    //编辑器初始化
    wangEditorInit() {
      var __this = this;
      __this.editor = new E("#div1");
      // 配置 server 接口地址
      __this.editor.config.uploadImgServer = process.env.VUE_APP_APIHOST + 'file/uploadimg';
      // 限制大小,默认限制图片大小是 5M 
      __this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024;
      //限制类型
      __this.editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      //自定义参数
      __this.editor.config.uploadImgParams = {
        "Authorization": "Bearer " + store.getters.token
      };
      // 自定义 header
      __this.editor.config.uploadImgHeaders = {
        "Authorization": "Bearer " + store.getters.token
      };
      // 自定义 timeout 时间 
      __this.editor.config.uploadImgTimeout = 60 * 1000;
      //自定义动作配置
      __this.editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function (xhr) {
          //console.log(xhr);
          // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: '需要提示给用户的错误信息'
          // };
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function (xhr) {
          console.log('success', xhr);
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function (xhr, editor, resData) {
          console.log('fail', resData);
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          console.log('error', xhr, resData);
        },
        // 上传图片超时
        timeout: function (xhr) {
          console.log('timeout');
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
          // result 即服务端返回的接口
          console.log('customInsert', result);
          if (result.code != 0) {
            this.$message("保存出错：" + result.message);
          } else {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            result.data.forEach(element => {
              insertImgFn(element);
            });
          }
        }
      };
      //同步数据
      __this.editor.config.onchange = function (newHtml) {
        __this.configForm.pointContent = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms  // 修改为 500ms
      __this.editor.config.onchangeTimeout = 500;
      //启动编辑器
      __this.editor.create();
      return Promise.resolve();
    },
    //图片提交成功回调
    handleUploadSuccess(response, file, fileList) {
      this.loading = true;
      if (response.data && response.data.length > 0) {
        this.configForm.shareImage = response.data[0];
      }
      this.loading = false;
    },
    //图片提交成功回调
    handleUploadQrcodeSuccess(response, file, fileList) {
      this.loading = true;
      if (response.data && response.data.length > 0) {
        this.configForm.supportQrCode = response.data[0];
      }
      this.loading = false;
    },
    //图片提交失败回调
    handleUploadError(response, file, fileList) {
      this.$message.error(JSON.parse(response.message).msg);
    }
  }
};
</script>
<style lang="scss">
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
}
.shareImageSize {
  max-width: 100%;
  max-height: 100%;
  min-width: 300px;
  height: 500px;
}
.qrCodeImageSize {
  max-width: 100%;
  max-height: 100%;
  min-width: 200px;
  min-height: 200px;
}
</style>